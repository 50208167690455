import { Controller } from "react-hook-form";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
} from "@mui/material";
import {
  heightOptions,
  maritalStatusOptions,
  manglikStatusOptions,
  qualificationOptions,
  ageRangeOptions,
} from "../../utility/SelectBoxValues";

const PartnerPreferences = ({ control, errors }) => {
  return (
    <>
      <Typography variant={"strong"} component={"h2"} sx={{ p: "16px" }}>
        Partner Preferences:
      </Typography>
      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset">
          <InputLabel>Age Range From</InputLabel>
          <Controller
            name="preferredAgeFrom"
            control={control}
            render={({ field }) => (
              <Select {...field} label="Age Range From">
                {ageRangeOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset">
          <InputLabel>Age Range To</InputLabel>
          <Controller
            name="preferredAgeTo"
            control={control}
            render={({ field }) => (
              <Select {...field} label="Age Range To">
                {ageRangeOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset">
          <InputLabel>Height Range From</InputLabel>
          <Controller
            name="preferredHeightFrom"
            control={control}
            render={({ field }) => (
              <Select {...field} label="Height Range From">
                {heightOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset">
          <InputLabel>Height Range To</InputLabel>
          <Controller
            name="preferredHeightTo"
            control={control}
            render={({ field }) => (
              <Select {...field} label="Height Range To">
                {heightOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset">
          <InputLabel>Qualification From</InputLabel>
          <Controller
            name="preferredQualificationFrom"
            control={control}
            render={({ field }) => (
              <Select {...field} label="Highest Qualification">
                {qualificationOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset">
          <InputLabel>Qualification To</InputLabel>
          <Controller
            name="preferredQualificationTo"
            control={control}
            render={({ field }) => (
              <Select {...field} label="Highest Qualification">
                {qualificationOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset">
          <InputLabel>Marital Status</InputLabel>
          <Controller
            name="preferredMaritalStatus"
            control={control}
            render={({ field }) => (
              <Select {...field} label="Marital Status">
                {maritalStatusOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset">
          <InputLabel>Manglik Status</InputLabel>
          <Controller
            name="preferredManglikStatus"
            control={control}
            render={({ field }) => (
              <Select {...field} label="Manglik Status">
                {manglikStatusOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>
    </>
  );
};

export default PartnerPreferences;
