// src/redux/sagas/profiles.phpaga.js
import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  fetchProfiles,
  fetchProfilesSuccess,
  fetchProfilesFailure,
  fetchProfile,
  fetchProfileSuccess,
  fetchProfileFailure,
  addProfile,
  addProfileSuccess,
  addProfileFailure,
  updateProfile,
  updateProfileSuccess,
  updateProfileFailure,
  deleteProfile,
  deleteProfileSuccess,
  deleteProfileFailure,
} from "./action";
import { showAlert } from "../AlertMessage/action";

const apiUrl = process.env.REACT_APP_API_URL;

// Fetch all profiles
function* fetchProfilesSaga() {
  try {
    const response = yield call(axios.get, `${apiUrl}/profiles.php`);
    yield put(fetchProfilesSuccess(response.data));
  } catch (error) {
    yield put(fetchProfilesFailure(error.message));
  }
}

// Fetch a single profile
function* fetchProfileSaga(action) {
  try {
    const response = yield call(
      axios.get,
      `${apiUrl}/profiles.php?id=${action.payload}`
    );
    yield put(fetchProfileSuccess(response.data));
  } catch (error) {
    yield put(fetchProfileFailure(error.message));
  }
}

// Add a new profile
function* addProfileSaga(action) {
  try {
    const response = yield call(
      axios.post,
      `${apiUrl}/profiles.php`,
      action.payload.data
    );
    yield put(showAlert(response.data.message, "success"));
    action.payload.navigate("/admin/profiles");
  } catch (error) {
    yield put(addProfileFailure(error.message));
    yield put(showAlert(error.message, "error"));
  }
}

// Update an existing profile
function* updateProfileSaga(action) {
  try {
    const response = yield call(
      axios.put,
      `${apiUrl}/profiles.php?id=${action.payload.id}`,
      action.payload.data
    );
    yield put(showAlert(response.data.message, "success"));
    action.payload.navigate("/admin/profiles");
  } catch (error) {
    yield put(updateProfileFailure(error.message));
  }
}

// Delete a profile
function* deleteProfileSaga(action) {
  try {
    const response = yield call(
      axios.delete,
      `${apiUrl}/profiles.php?id=${action.payload}`
    );
    yield put(deleteProfileSuccess(action.payload));
    yield put(showAlert(response.data.message, "success"));
  } catch (error) {
    yield put(deleteProfileFailure(error.message));
  }
}

export default function* profileSaga() {
  yield takeLatest(fetchProfiles.type, fetchProfilesSaga);
  yield takeLatest(fetchProfile.type, fetchProfileSaga);
  yield takeLatest(addProfile.type, addProfileSaga);
  yield takeLatest(updateProfile.type, updateProfileSaga);
  yield takeLatest(deleteProfile.type, deleteProfileSaga);
}
