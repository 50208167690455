import { Controller } from "react-hook-form";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
} from "@mui/material";
import { qualificationOptions } from "../../utility/SelectBoxValues";

const EducationalBackground = ({ control, errors }) => {
  return (
    <>
      <Typography variant={"strong"} component={"h2"} sx={{ p: "16px" }}>
        Educational Background:
      </Typography>
      <Grid item xs={12}>
        <FormControl
          fullWidth
          component="fieldset"
          error={!!errors.highestQualification}
        >
          <InputLabel>Highest Qualification*</InputLabel>
          <Controller
            name="highestQualification"
            control={control}
            rules={{ required: "Highest Qualification is required" }}
            render={({ field }) => (
              <Select {...field} label="Highest Qualification">
                {qualificationOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="nameOfInstitution"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Name of Institution"
              variant="outlined"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="fieldOfStudy"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Field of Study"
              variant="outlined"
            />
          )}
        />
      </Grid>
    </>
  );
};

export default EducationalBackground;
