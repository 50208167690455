import { createAction } from "@reduxjs/toolkit";

// Action types
export const fetchProfiles = createAction("FETCH_PROFILES");
export const fetchProfilesSuccess = createAction("FETCH_PROFILES_SUCCESS");
export const fetchProfilesFailure = createAction("FETCH_PROFILES_FAILURE");

export const fetchProfile = createAction("FETCH_PROFILE");
export const fetchProfileSuccess = createAction("FETCH_PROFILE_SUCCESS");
export const fetchProfileFailure = createAction("FETCH_PROFILE_FAILURE");

export const addProfile = createAction("ADD_PROFILE");
export const addProfileSuccess = createAction("ADD_PROFILE_SUCCESS");
export const addProfileFailure = createAction("ADD_PROFILE_FAILURE");

export const updateProfile = createAction("UPDATE_PROFILE");
export const updateProfileSuccess = createAction("UPDATE_PROFILE_SUCCESS");
export const updateProfileFailure = createAction("UPDATE_PROFILE_FAILURE");

export const deleteProfile = createAction("DELETE_PROFILE");
export const deleteProfileSuccess = createAction("DELETE_PROFILE_SUCCESS");
export const deleteProfileFailure = createAction("DELETE_PROFILE_FAILURE");
