import { Controller } from "react-hook-form";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Checkbox,
  FormGroup,
  Typography,
} from "@mui/material";
import {
  doshOptions,
  maritalStatusOptions,
  manglikStatusOptions,
} from "../../utility/SelectBoxValues";

const PersonalInformation = ({ control, errors, watch }) => {
  const maritalStatus = watch("maritalStatus");
  return (
    <>
      <Typography variant={"strong"} component={"h2"} sx={{ p: "16px" }}>
        Personal Information:
      </Typography>
      <Grid item xs={12}>
        <Controller
          name="registrationNumber"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Registration Number"
              variant="outlined"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="fullName"
          control={control}
          rules={{ required: "Full Name is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Full Name*"
              variant="outlined"
              error={!!errors.fullName}
              helperText={errors.fullName ? errors.fullName.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset" error={!!errors.gender}>
          <FormLabel component="legend">Gender*</FormLabel>
          <Controller
            name="gender"
            control={control}
            rules={{ required: "Gender is required" }}
            render={({ field }) => (
              <RadioGroup row {...field}>
                <FormControlLabel
                  value="Male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="Female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl
          fullWidth
          component="fieldset"
          error={!!errors.maritalStatus}
        >
          <InputLabel>Marital Status*</InputLabel>
          <Controller
            name="maritalStatus"
            control={control}
            rules={{ required: "Marital Status is required" }}
            render={({ field }) => (
              <Select {...field} label="Marital Status">
                {maritalStatusOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>
      {(maritalStatus === "Divorced" ||
        maritalStatus === "Widowed" ||
        maritalStatus === "Separated") && (
        <Grid item xs={12}>
          <Controller
            name="numberOfChildren"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Number of children from previous marriage(s)"
                variant="outlined"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Controller
          name="dateOfBirth"
          control={control}
          rules={{ required: "Date of Birth is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Date of Birth*"
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.dateOfBirth}
              helperText={errors.dateOfBirth ? errors.dateOfBirth.message : ""}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="birthCity"
          control={control}
          rules={{ required: "Place of Birth City is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Place of Birth City*"
              variant="outlined"
              error={!!errors.birthCity}
              helperText={errors.birthCity ? errors.birthCity.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="birthState"
          control={control}
          rules={{ required: "Place of Birth State is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Place of Birth State*"
              variant="outlined"
              error={!!errors.birthState}
              helperText={errors.birthState ? errors.birthState.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="birthCountry"
          control={control}
          rules={{ required: "Place of Birth Country is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Place of Birth Country*"
              variant="outlined"
              error={!!errors.birthCountry}
              helperText={
                errors.birthCountry ? errors.birthCountry.message : ""
              }
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="timeOfBirth"
          control={control}
          rules={{ required: "Time of Birth is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Time of Birth*"
              variant="outlined"
              type="time"
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.timeOfBirth}
              helperText={errors.timeOfBirth ? errors.timeOfBirth.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl
          fullWidth
          component="fieldset"
          error={!!errors.manglikStatus}
        >
          <InputLabel>Manglik Status*</InputLabel>
          <Controller
            name="manglikStatus"
            control={control}
            rules={{ required: "Manglik Status is required" }}
            render={({ field }) => (
              <Select {...field} label="Manglik Status">
                {manglikStatusOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset" error={!!errors.dosh}>
          <FormLabel component="legend">Any Other Dosh</FormLabel>
          <Controller
            name="dosh"
            control={control}
            render={({ field }) => (
              <FormGroup row>
                {doshOptions.map((doshValue, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={field.value.includes(doshValue.value)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            field.onChange([...field.value, doshValue.value]);
                          } else {
                            field.onChange(
                              field.value.filter(
                                (item) => item !== doshValue.value
                              )
                            );
                          }
                        }}
                      />
                    }
                    label={doshValue.label}
                  />
                ))}
              </FormGroup>
            )}
          />
        </FormControl>
      </Grid>
      {/* <Grid item xs={12}>
            <Controller
              name="image"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="file"
                  label="Image"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    accept: "image/*",
                  }}
                />
              )}
            />
          </Grid> */}
    </>
  );
};

export default PersonalInformation;
