export const profileFormDefaultValues = (profile = {}) => {
  return {
    registrationNumber: profile?.registrationNumber || "",
    fullName: profile?.fullName || "",
    gender: profile?.gender || "",
    maritalStatus: profile?.maritalStatus || "",
    numberOfChildren: profile?.numberOfChildren || "",
    dateOfBirth: profile?.dateOfBirth || "",
    birthCity: profile?.birthCity || "",
    birthState: profile?.birthState || "",
    birthCountry: profile?.birthCountry || "",
    timeOfBirth: profile?.timeOfBirth || "",
    manglikStatus: profile?.manglikStatus || "",
    dosh: profile.dosh ? profile.dosh.split("|") : [],
    image: "",
    height: profile?.height || "",
    weight: profile?.weight || "",
    complexion: profile?.complexion || "",
    disabilities: profile?.disabilities || "",
    address: profile?.address || "",
    city: profile?.city || "",
    state: profile?.state || "",
    pinCode: profile?.pinCode || "",
    mobileNo: profile?.mobileNo || "",
    email: profile?.email || "",
    alternateContact: profile?.alternateContact || "",
    fathersName: profile?.fathersName || "",
    fatherAlive: profile?.fatherAlive || "",
    fatherOccupation: profile?.fatherOccupation || "",
    mothersName: profile?.mothersName || "",
    motherAlive: profile?.motherAlive || "",
    motherOccupation: profile?.motherOccupation || "",
    brotherTotalNumber: profile?.brotherTotalNumber || "",
    marriedBrotherNumber: profile?.marriedBrotherNumber || "",
    sisterTotalNumber: profile?.sisterTotalNumber || "",
    marriedSisterNumber: profile?.marriedSisterNumber || "",
    originInSindh: profile?.originInSindh || "",
    highestQualification: profile?.highestQualification || "",
    nameOfInstitution: profile?.nameOfInstitution || "",
    fieldOfStudy: profile?.fieldOfStudy || "",
    occupation: profile?.occupation || "",
    currentJobTitle: profile?.currentJobTitle || "",
    employerName: profile?.employerName || "",
    annualIncome: profile?.annualIncome || "",
    jobLocation: profile?.jobLocation || "",
    preferredAgeFrom: profile?.preferredAgeFrom || "",
    preferredAgeTo: profile?.preferredAgeTo || "",
    preferredHeightFrom: profile?.preferredHeightFrom || "",
    preferredHeightTo: profile?.preferredHeightTo || "",
    preferredQualificationFrom: profile?.preferredQualificationFrom || "",
    preferredQualificationTo: profile?.preferredQualificationTo || "",
    preferredMaritalStatus: profile?.preferredMaritalStatus || "",
    preferredManglikStatus: profile?.preferredManglikStatus || "",
    status: profile?.status || "Active",
  };
};
