import { Controller } from "react-hook-form";
import { TextField, Grid, Typography } from "@mui/material";

const ContactInformation = ({ control, errors }) => {
  return (
    <>
      <Typography variant={"strong"} component={"h2"} sx={{ p: "16px" }}>
        Contact Information:
      </Typography>
      <Grid item xs={12}>
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Address"
              variant="outlined"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <TextField {...field} fullWidth label="City" variant="outlined" />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <TextField {...field} fullWidth label="State" variant="outlined" />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="pinCode"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Pin Code"
              variant="outlined"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="mobileNo"
          control={control}
          rules={{ required: "Mobile No. is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Mobile No.*"
              variant="outlined"
              error={!!errors.mobileNo}
              helperText={errors.mobileNo && errors.mobileNo.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="email"
          control={control}
          rules={{ required: "Email is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="E-mail*"
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="alternateContact"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Alternate Contact Number"
              variant="outlined"
            />
          )}
        />
      </Grid>
    </>
  );
};

export default ContactInformation;
