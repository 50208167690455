import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import Activities from "../pages/Activities";
import Gallery from "../pages/Gallery";
import Boys from "../pages/Boys";
import Girls from "../pages/Girls";
import ContactUs from "../pages/ContactUs";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";

/**
 * Access Level
 * admin: Only access to Admin
 * protected: Access to Admin and Loged in Users
 * public: Access to All
 *
 * headerMainMenu: true | false [whether to show on main navigation]
 *
 * redirectIfAuthenticated: true | false [if True, route will redirect to home page if logged in]
 */
export const routes = {
  Home: {
    headerMainMenu: true,
    path: "/",
    component: <Home />,
    accessLevel: "public",
  },
  "About Us": {
    headerMainMenu: true,
    path: "/about-us",
    component: <AboutUs />,
    accessLevel: "public",
  },
  Activities: {
    headerMainMenu: true,
    path: "/activities",
    component: <Activities />,
    accessLevel: "public",
  },
  Gallery: {
    headerMainMenu: true,
    path: "/gallery",
    component: <Gallery />,
    accessLevel: "public",
  },
  Boys: {
    headerMainMenu: true,
    path: "/boys",
    component: <Boys />,
    accessLevel: "public",
  },
  Girls: {
    headerMainMenu: true,
    path: "/girls",
    component: <Girls />,
    accessLevel: "public",
  },
  "Contact Us": {
    headerMainMenu: true,
    path: "/contact-us",
    component: <ContactUs />,
    accessLevel: "public",
  },
  Login: {
    headerMainMenu: true,
    path: "/login",
    component: <Login />,
    accessLevel: "public",
    redirectIfAuthenticated: true,
  },
  "Sign Up": {
    headerMainMenu: true,
    path: "/sign-up",
    component: <SignUp />,
    accessLevel: "public",
    redirectIfAuthenticated: true,
  },
  "Forgot Password": {
    headerMainMenu: true,
    path: "/forgot-password",
    component: <ForgotPassword />,
    accessLevel: "public",
    redirectIfAuthenticated: true,
  },
};
