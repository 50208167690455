import { Controller } from "react-hook-form";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Typography,
} from "@mui/material";
import {
  heightOptions,
  weightOptions,
  complexionOptions,
} from "../../utility/SelectBoxValues";

const PhysicalAttributes = ({ control, errors }) => {
  return (
    <>
      <Typography variant={"strong"} component={"h2"} sx={{ p: "16px" }}>
        Physical Attributes:
      </Typography>
      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset" error={!!errors.height}>
          <InputLabel>Height*</InputLabel>
          <Controller
            name="height"
            control={control}
            rules={{ required: "Height is required" }}
            render={({ field }) => (
              <Select {...field} label="Height">
                {heightOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset" error={!!errors.weight}>
          <InputLabel>Weight*</InputLabel>
          <Controller
            name="weight"
            control={control}
            rules={{ required: "Weight is required" }}
            render={({ field }) => (
              <Select {...field} label="Weight">
                {weightOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset" error={!!errors.complexion}>
          <FormLabel component="legend">Complexion*</FormLabel>
          <Controller
            name="complexion"
            control={control}
            render={({ field }) => (
              <RadioGroup row {...field}>
                {complexionOptions.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl
          fullWidth
          component="fieldset"
          error={!!errors.disabilities}
        >
          <FormLabel component="legend">Disabilities (if any)*</FormLabel>
          <Controller
            name="disabilities"
            control={control}
            render={({ field }) => (
              <RadioGroup row {...field}>
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>
    </>
  );
};

export default PhysicalAttributes;
