import { Controller } from "react-hook-form";
import {
  TextField,
  FormControl,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Typography,
} from "@mui/material";

const FamilyDetails = ({ control, errors }) => {
  return (
    <>
      <Typography variant={"strong"} component={"h2"} sx={{ p: "16px" }}>
        Family Details
      </Typography>
      <Grid item xs={12}>
        <Controller
          name="fathersName"
          control={control}
          rules={{ required: "Father's Name is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Father's Name*"
              variant="outlined"
              error={!!errors.fathersName}
              helperText={errors.fathersName && errors.fathersName.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl
          fullWidth
          component="fieldset"
          error={!!errors.fatherAlive}
        >
          <FormLabel component="legend">Father Alive*</FormLabel>
          <Controller
            name="fatherAlive"
            control={control}
            rules={{ required: "Father Alive is required" }}
            render={({ field }) => (
              <RadioGroup row {...field}>
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="fatherOccupation"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Father's Occupation"
              variant="outlined"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="mothersName"
          control={control}
          rules={{ required: "Mother's Name is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Mother's Name*"
              variant="outlined"
              error={!!errors.mothersName}
              helperText={errors.mothersName && errors.mothersName.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl
          fullWidth
          component="fieldset"
          error={!!errors.motherAlive}
        >
          <FormLabel component="legend">Mother Alive*</FormLabel>
          <Controller
            name="motherAlive"
            control={control}
            rules={{ required: "Mother Alive is required" }}
            render={({ field }) => (
              <RadioGroup row {...field}>
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="motherOccupation"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Mother's Occupation"
              variant="outlined"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="brotherTotalNumber"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Brother Total Number"
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="marriedBrotherNumber"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Married Brother Number"
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="sisterTotalNumber"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Sister Total Number"
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="marriedSisterNumber"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Married Sister Number"
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="originInSindh"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Origin In Sindh"
              variant="outlined"
            />
          )}
        />
      </Grid>
    </>
  );
};

export default FamilyDetails;
