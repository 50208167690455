import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const Footer = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {new Date().getFullYear()}{" "}
      <Link color="inherit" href="https://www.mpsindhubhawantrust.org/">
        www.mpsindhubhawantrust.org
      </Link>
      {" All Rights Reserved."}
    </Typography>
  );
};
export default Footer;
