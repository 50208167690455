export const heightOptions = [
  { value: "137", label: "4ft 6in - 137cm" },
  { value: "138", label: "4ft 6in - 138cm" },
  { value: "139", label: "4ft 7in - 139cm" },
  { value: "140", label: "4ft 7in - 140cm" },
  { value: "141", label: "4ft 7in - 141cm" },
  { value: "142", label: "4ft 8in - 142cm" },
  { value: "143", label: "4ft 8in - 143cm" },
  { value: "144", label: "4ft 9in - 144cm" },
  { value: "145", label: "4ft 9in - 145cm" },
  { value: "146", label: "4ft 10in - 146cm" },
  { value: "147", label: "4ft 10in - 147cm" },
  { value: "148", label: "4ft 10in - 148cm" },
  { value: "149", label: "4ft 11in - 149cm" },
  { value: "150", label: "4ft 11in - 150cm" },
  { value: "151", label: "4ft 11in - 151cm" },
  { value: "152", label: "5ft - 152cm" },
  { value: "153", label: "5ft - 153cm" },
  { value: "154", label: "5ft 1in - 154cm" },
  { value: "155", label: "5ft 1in - 155cm" },
  { value: "156", label: "5ft 1in - 156cm" },
  { value: "157", label: "5ft 2in - 157cm" },
  { value: "158", label: "5ft 2in - 158cm" },
  { value: "159", label: "5ft 2in - 159cm" },
  { value: "160", label: "5ft 3in - 160cm" },
  { value: "161", label: "5ft 3in - 161cm" },
  { value: "162", label: "5ft 4in - 162cm" },
  { value: "163", label: "5ft 4in - 163cm" },
  { value: "164", label: "5ft 4in - 164cm" },
  { value: "165", label: "5ft 5in - 165cm" },
  { value: "166", label: "5ft 5in - 166cm" },
  { value: "167", label: "5ft 6in - 167cm" },
  { value: "168", label: "5ft 6in - 168cm" },
  { value: "169", label: "5ft 6in - 169cm" },
  { value: "170", label: "5ft 7in - 170cm" },
  { value: "171", label: "5ft 7in - 171cm" },
  { value: "172", label: "5ft 8in - 172cm" },
  { value: "173", label: "5ft 8in - 173cm" },
  { value: "174", label: "5ft 8in - 174cm" },
  { value: "175", label: "5ft 9in - 175cm" },
  { value: "176", label: "5ft 9in - 176cm" },
  { value: "177", label: "5ft 10in - 177cm" },
  { value: "178", label: "5ft 10in - 178cm" },
  { value: "179", label: "5ft 10in - 179cm" },
  { value: "180", label: "5ft 11in - 180cm" },
  { value: "181", label: "5ft 11in - 181cm" },
  { value: "182", label: "6ft - 182cm" },
  { value: "183", label: "6ft - 183cm" },
  { value: "184", label: "6ft - 184cm" },
  { value: "185", label: "6ft 1in - 185cm" },
  { value: "186", label: "6ft 1in - 186cm" },
  { value: "187", label: "6ft 2in - 187cm" },
  { value: "188", label: "6ft 2in - 188cm" },
  { value: "189", label: "6ft 2in - 189cm" },
  { value: "190", label: "6ft 3in - 190cm" },
  { value: "191", label: "6ft 3in - 191cm" },
  { value: "192", label: "6ft 3in - 192cm" },
  { value: "193", label: "6ft 4in - 193cm" },
  { value: "194", label: "6ft 4in - 194cm" },
  { value: "195", label: "6ft 5in - 195cm" },
  { value: "196", label: "6ft 5in - 196cm" },
  { value: "197", label: "6ft 5in - 197cm" },
  { value: "198", label: "6ft 6in - 198cm" },
  { value: "199", label: "6ft 6in - 199cm" },
  { value: "200", label: "6ft 7in - 200cm" },
  { value: "201", label: "6ft 7in - 201cm" },
  { value: "202", label: "6ft 7in - 202cm" },
  { value: "203", label: "6ft 8in - 203cm" },
  { value: "204", label: "6ft 8in - 204cm" },
  { value: "205", label: "6ft 9in - 205cm" },
  { value: "206", label: "6ft 9in - 206cm" },
  { value: "207", label: "6ft 9in - 207cm" },
  { value: "208", label: "6ft 10in - 208cm" },
  { value: "209", label: "6ft 10in - 209cm" },
  { value: "210", label: "6ft 11in - 210cm" },
  { value: "211", label: "6ft 11in - 211cm" },
  { value: "212", label: "6ft 11in - 212cm" },
  { value: "213", label: "7ft - 213cm" },
];

export const weightOptions = Array.from({ length: 116 }, (_, i) => ({
  label: `${25 + i} kg`,
  value: 25 + i,
}));

export const doshOptions = [
  { value: "No Dosh", label: "No Dosh" },
  { value: "Kuja Dosha", label: "Kuja Dosha" },
  { value: "Shani Dosha", label: "Shani Dosha" },
  { value: "Pitra Dosha", label: "Pitra Dosha" },
  { value: "Rahu Dosha", label: "Rahu Dosha" },
  { value: "Ketu Dosha", label: "Ketu Dosha" },
  { value: "Gandmool Dosha", label: "Gandmool Dosha" },
  { value: "Nadi Dosha", label: "Nadi Dosha" },
  { value: "Not Sure", label: "Not Sure" },
];

export const maritalStatusOptions = [
  { value: "Never Married", label: "Never Married" },
  { value: "Divorced", label: "Divorced" },
  { value: "Widowed", label: "Widowed" },
  { value: "Separated", label: "Separated" },
];

export const manglikStatusOptions = [
  { value: "Manglik", label: "Manglik" },
  { value: "Non Manglik", label: "Non Manglik" },
  { value: "Not Sure", label: "Not Sure" },
];

export const qualificationOptions = [
  { value: "Non Matrices", label: "Non Matrices" },
  { value: "Metric", label: "Metric" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Diploma", label: "Diploma" },
  { value: "Gradution", label: "Gradution" },
  { value: "PostGradution", label: "PostGradution" },
  { value: "Doctorate", label: "Doctorate" },
];

export const ageRangeOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

export const complexionOptions = [
  { value: "Very Fair", label: "Very Fair" },
  { value: "Fair", label: "Fair" },
  { value: "Wheatish", label: "Wheatish" },
];
