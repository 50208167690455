// routes.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
//import { useSelector } from "react-redux";
import AdminIndex from "./pages/Admin";
import Users from "./pages/Admin/Users";
import UserForm from "./pages/Admin/UserForm";
import UserDetailView from "./pages/Admin/UserDetailView";
import Profiles from "./pages/Admin/Profiles";
import ProfileForm from "./pages/Admin/ProfileForm";
import ProfileFormUpdate from "./pages/Admin/ProfileFormUpdate";
import ProfileDetailView from "./pages/Admin/ProfileDetailView";
import NotFound from "./pages/NotFound";
import { routes } from "./utility/Routes";
/*
const ProtectedRoute = ({ element }) => {
  const { id } = useSelector((state) => state?.user || {});
  const isAuthenticated = id ? true : false;
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};
*/

const AdminRoute = ({ element }) => {
  //const { id } = useSelector((state) => state?.user || {});
  //const isAuthenticated = id ? true : false;
  const isAuthenticated = true;
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

const ApplicationRoutes = ({ isAuthenticated }) => (
  <Router>
    <Routes>
      {Object.values(routes).map((menu, index) => {
        if (menu.accessLevel === "public") {
          let element = menu.componexnt;
          if (menu.redirectIfAuthenticated) {
            element = isAuthenticated ? <Navigate to="/" /> : menu.component;
          }
          return <Route key={index} path={menu.path} element={element} />;
        } else {
          return null;
        }
      })}

      <Route path="/admin" element={<AdminRoute element={<AdminIndex />} />} />
      <Route path="/admin/users" element={<AdminRoute element={<Users />} />} />
      <Route
        path="/admin/user/create"
        element={<AdminRoute element={<UserForm />} />}
      />
      <Route
        path="/admin/user/edit/:id"
        element={<AdminRoute element={<UserForm />} />}
      />
      <Route
        path="/admin/user/view/:id"
        element={<AdminRoute element={<UserDetailView />} />}
      />
      <Route
        path="/admin/profiles"
        element={<AdminRoute element={<Profiles />} />}
      />
      <Route
        path="/admin/profile/create"
        element={<AdminRoute element={<ProfileForm />} />}
      />
      <Route
        path="/admin/profile/edit/:id"
        element={<AdminRoute element={<ProfileFormUpdate />} />}
      />
      <Route
        path="/admin/profile/view/:id"
        element={<AdminRoute element={<ProfileDetailView />} />}
      />
      <Route path="*" element={<AdminRoute element={<NotFound />} />} />
    </Routes>
  </Router>
);

export default ApplicationRoutes;
