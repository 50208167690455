import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addProfile } from "../../redux/Profile/action";
import { useNavigate, useParams } from "react-router-dom";
import { updateProfile } from "../../redux/Profile/action";
import PersonalInformation from "../../components/Registration/PersonalInformation";
import PhysicalAttributes from "../../components/Registration/PhysicalAttributes";
import ContactInformation from "../../components/Registration/ContactInformation";
import FamilyDetails from "../../components/Registration/FamilyDetails";
import EducationalBackground from "../../components/Registration/EducationalBackground";
import ProfessionalDetails from "../../components/Registration/ProfessionalDetails";
import PartnerPreferences from "../../components/Registration/PartnerPreferences";
import { profileFormDefaultValues } from "../../utility/FormDefaultValues";

const ProfileForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state?.profileData?.profile || {});
  const profile = id ? profileData : {}; //If no id, than set profile is empty

  const navigate = useNavigate();
  const {
    control,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: profileFormDefaultValues(profile),
  });
  const onSubmit = (data) => {
    const selectedDosh = getValues("dosh").join("|");
    const updatedData = { ...data, dosh: selectedDosh };
    if (id) {
      dispatch(updateProfile({ data: updatedData, id, navigate: navigate }));
    } else {
      dispatch(addProfile({ data: updatedData, navigate: navigate }));
    }
  };

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      const errorField = document.querySelector(
        `[name="${Object.keys(errors)[0]}"]`
      );
      if (errorField) {
        errorField.scrollIntoView({ behavior: "smooth", block: "center" });
        errorField.focus();
      }
    }
  }, [errors]);

  return (
    <Box sx={{ flexGrow: 1, p: 4 }}>
      <Box mb={3} display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/admin/profiles")}
        >
          List Profiles
        </Button>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <PersonalInformation
            control={control}
            errors={errors}
            watch={watch}
          />
          <PhysicalAttributes control={control} errors={errors} />
          <ContactInformation control={control} errors={errors} />
          <FamilyDetails control={control} errors={errors} />
          <EducationalBackground control={control} errors={errors} />
          <ProfessionalDetails control={control} errors={errors} />
          <PartnerPreferences control={control} errors={errors} />
          <Grid item xs={12}>
            <FormControl fullWidth component="fieldset" error={!!errors.status}>
              <InputLabel>Profile Status*</InputLabel>
              <Controller
                name="status"
                control={control}
                rules={{ required: "Profile Status is required" }}
                render={({ field }) => (
                  <Select {...field} label="Profile Status*">
                    <MenuItem value={"Active"}>Active</MenuItem>
                    <MenuItem value={"Inactive"}>Inactive</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ProfileForm;
