import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchProfile } from "../../redux/Profile/action";
import ProfileForm from "./ProfileForm";

const ProfileFormUpdate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const profileLoading = useSelector((state) => state.profileData.loading);
  useEffect(() => {
    if (id) {
      dispatch(fetchProfile(id));
    }
  }, [id, dispatch]);
  return !profileLoading ? <ProfileForm /> : null;
};

export default ProfileFormUpdate;
