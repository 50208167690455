import { combineReducers } from "redux";
import alertMessageReducer from "./AlertMessage/reducer";
import loginReducer from "./Login/reducer";
import profileReducer from "./Profile/reducer";

const rootReducer = combineReducers({
  alertMessage: alertMessageReducer,
  user: loginReducer,
  profileData: profileReducer,
});

export default rootReducer;
