import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchProfiles, deleteProfile } from "../../redux/Profile/action";

const Profiles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profiles = useSelector((state) => state?.profileData?.profiles || []);

  const [open, setOpen] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState(null);

  useEffect(() => {
    dispatch(fetchProfiles());
  }, [dispatch]);

  const handleClickOpen = (id) => {
    setSelectedProfileId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProfileId(null);
  };

  const handleDelete = () => {
    dispatch(deleteProfile(selectedProfileId));
    handleClose();
  };

  const columns = [
    { field: "id", headerName: "ID", width: 30 },
    {
      field: "registrationNumber",
      headerName: "#Registration",
      width: 100,
    },
    { field: "fullName", headerName: "Name", flex: 1 },
    { field: "gender", headerName: "Gender", width: 100 },
    { field: "maritalStatus", headerName: "Marital Status", width: 120 },
    {
      field: "dateOfBirth",
      headerName: "DOB",
      width: 100,
      renderCell: (params) => (
        <>{params.row.dateOfBirth.split("-").reverse().join("/")}</>
      ),
    },
    { field: "manglikStatus", headerName: "Maglik Status", flex: 1 },
    { field: "status", headerName: "Profile Status", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <IconButton
            aria-label="edit"
            onClick={() => navigate(`/admin/profile/edit/${params.row.id}`)}
          >
            <Edit />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleClickOpen(params.row.id)}
          >
            <Delete />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box p={3}>
      <Box mb={3} display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/admin/profile/create")}
        >
          Create Profile
        </Button>
      </Box>
      <div style={{ height: "100%", width: "100%" }}>
        <DataGrid
          rows={profiles}
          columns={columns}
          autoHeight
          pagination
          pageSize={5}
          pageSizeOptions={[5, 10, 20, 30]}
          checkboxSelection
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 30 },
            },
          }}
        />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Profiles;
