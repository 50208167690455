import { Controller } from "react-hook-form";
import { TextField, Grid, Typography } from "@mui/material";

const ProfessionalDetails = ({ control, errors }) => {
  return (
    <>
      <Typography variant={"strong"} component={"h2"} sx={{ p: "16px" }}>
        Professional Details:
      </Typography>
      <Grid item xs={12}>
        <Controller
          name="occupation"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Occupation"
              variant="outlined"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="currentJobTitle"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Current Job Title"
              variant="outlined"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="employerName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Employer Name"
              variant="outlined"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="annualIncome"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Annual Income(in Rs)"
              variant="outlined"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="jobLocation"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label="Job Location"
              variant="outlined"
            />
          )}
        />
      </Grid>
    </>
  );
};

export default ProfessionalDetails;
