import { createReducer } from "@reduxjs/toolkit";
import {
  fetchProfiles,
  fetchProfilesSuccess,
  fetchProfilesFailure,
  fetchProfile,
  fetchProfileSuccess,
  fetchProfileFailure,
  addProfile,
  addProfileSuccess,
  addProfileFailure,
  updateProfile,
  updateProfileSuccess,
  updateProfileFailure,
  deleteProfile,
  deleteProfileSuccess,
  deleteProfileFailure,
} from "./action";

const initialState = {
  profiles: [],
  profile: null,
  loading: false,
  error: null,
};

const profileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchProfiles, (state) => {
      state.loading = true;
    })
    .addCase(fetchProfilesSuccess, (state, action) => {
      state.loading = false;
      state.profiles = action.payload;
    })
    .addCase(fetchProfilesFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(fetchProfile, (state) => {
      state.loading = true;
    })
    .addCase(fetchProfileSuccess, (state, action) => {
      state.loading = false;
      state.profile = action.payload;
    })
    .addCase(fetchProfileFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(addProfile, (state) => {
      state.loading = true;
    })
    .addCase(addProfileSuccess, (state, action) => {
      state.loading = false;
      state.profiles.push(action.payload);
    })
    .addCase(addProfileFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(updateProfile, (state) => {
      state.loading = true;
    })
    .addCase(updateProfileSuccess, (state, action) => {
      state.loading = false;
      const index = state.profiles.findIndex(
        (profile) => profile.id === action.payload.id
      );
      if (index !== -1) {
        state.profiles[index] = action.payload;
      }
    })
    .addCase(updateProfileFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(deleteProfile, (state) => {
      state.loading = true;
    })
    .addCase(deleteProfileSuccess, (state, action) => {
      state.loading = false;
      state.profiles = state.profiles.filter(
        (profile) => profile.id !== action.payload
      );
    })
    .addCase(deleteProfileFailure, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});

export default profileReducer;
